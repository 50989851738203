import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Partner Images
import MCMLogo from 'assets/imgs/MeetOurPartners/MusicCareerMap.png';
import MDRLogo from 'assets/imgs/MeetOurPartners/MDR_Logo_RGB.jpg';
import MIALogo from 'assets/imgs/MeetOurPartners/MIA_Logo_Official_1000x.png';
import TCRLogo from 'assets/imgs/MeetOurPartners/TCRG_Horizontal_Logo_Web.jpg';

// SCSS
import styles from './MeetOurPartners.module.scss';

const MeetOurPartners: React.FC = () => {
  return (
    <Container className={`${styles.meetOurPartners}`}>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.meetOurPartnersTitle}>Meet Our Partners</div>
        </Col>

        <Col xl={12} lg={12} md={12} sm={12}>
          <Row>
            <Col xl={3} lg={3} md={3} sm={6} className={styles.partnerItem}>
              <div className={styles.partnerImage}>
                <a
                  href="https://musiccareermap.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={MCMLogo} alt="Music Career Map" />
                </a>
              </div>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} className={styles.partnerItem}>
              <div className={styles.partnerImage}>
                <a
                  href="https://musiciansdeskreference.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={MDRLogo} alt="Musicians Desk Reference" />
                </a>
              </div>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} className={styles.partnerItem}>
              <div className={styles.partnerImage}>
                <a
                  href="https://musicindustry.academy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={MIALogo} alt="Music industry academy" />
                </a>
              </div>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} className={styles.partnerItem}>
              <div className={styles.partnerImage}>
                <a
                  href="http://thecounterrhythmgroup.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={TCRLogo} alt="The Counter Rhythm Group" />
                </a>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(MeetOurPartners);
