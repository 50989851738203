import { API_ENDPOINTS } from 'constants/Constants';
import { API_METHODS, DEFAULT_API_HEADER } from 'config/ApiConfig';

import { UserCredentials } from 'interfaces/Register.types';

const stringifyMe = (obj: any) => JSON.stringify(obj);

/**
 *
 * @param userDetails All the user details input by new user while registering
 * This service is used for registering the users
 */
export const registerUserService = (userDetails: any) => {
  return fetch(API_ENDPOINTS.USERS, {
    method: API_METHODS.POST,
    headers: {
      ...DEFAULT_API_HEADER,
    },
    body: stringifyMe({ ...userDetails }),
  });
};

/**
 *
 * @param userCredentails
 * Service for logging in the user
 */
export const loginUserService = (userCredentails: UserCredentials) => {
  return fetch(API_ENDPOINTS.LOGIN, {
    method: API_METHODS.POST,
    headers: {
      ...DEFAULT_API_HEADER,
    },
    body: stringifyMe({ ...userCredentails }),
  });
};

/**
 * @param token A valid token which was sent to the user over email for resetting the password
 * @param password New password
 * Service for resetting the user's password
 */
export const resetPasswordService = (token: string, password: string) => {
  return fetch(`${API_ENDPOINTS.RESET_PASSWORD}/${token}`, {
    method: API_METHODS.POST,
    headers: {
      ...DEFAULT_API_HEADER,
    },
    body: stringifyMe({ password }),
  });
};

/**
 * @param email
 * Service used for sending an email to the user to let user change password
 */
export const forgotPasswordService = (email: string) => {
  return fetch(API_ENDPOINTS.FORGOT_PASSWORD, {
    method: API_METHODS.POST,
    headers: {
      ...DEFAULT_API_HEADER,
    },
    body: stringifyMe({ email }),
  });
};

export const updateUserAccountInfoService = async (
  userId: string,
  apiBody: any
) => {
  try {
    let finalBody: any = {};
    if (apiBody && Object.keys(apiBody).length) {
      Object.keys(apiBody).forEach((q) => {
        if (
          (apiBody[q] && apiBody[q].length) ||
          q === 'categories' ||
          q === 'additional_links' ||
          q === 'resume'
        ) {
          finalBody[q] = apiBody[q];
        }
      });
    }
    const response = await fetch(`${API_ENDPOINTS.USERS}/${userId}`, {
      method: API_METHODS.PUT,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: stringifyMe(finalBody),
    });

    if (response.ok) {
      const { token } = await response.json();
      return { token };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const fetchUserDetailsService = async (userId: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.USERS}/${userId}`, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const user = await response.json();
      return { user };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const fetchApplicantProfileService = async (slug: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.USER_PROFILE}/${slug}`, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const profile = await response.json();
      return { profile };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const closeUserAccountService = async (id: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.USERS}/${id}`, {
      method: API_METHODS.DELETE,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const { success } = await response.json();
      return { isClosed: success };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const reportUserService = async (userId: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.REPORT_USER}`, {
      method: API_METHODS.POST,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: stringifyMe({ userId: userId }),
    });

    if (response.ok) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (err) {
    return { success: false };
  }
};

export const downloadCredential = async (credId: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.CERTIFICATES}/${credId}`, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      return {
        error: await response.clone().text(),
      };
    } else {
      const data = await response.json();
      return { data };
    }
  } catch (err) {
    return { error: err.message };
  }
};
