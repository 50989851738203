import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

// SCSS
import styles from './Hero.module.scss';

// IMGs
import HeroBanner from '../../../../assets/imgs/Hero/hero-bg.png';

// SVGs
import Gesture from '../../../../assets/svgs/Hero/gesture.svg';
import Blob from '../../../../assets/svgs/Hero/blob-1.svg';
import Studio from '../../../../assets/svgs/Hero/studio.svg';
import Blob2 from '../../../../assets/svgs/Hero/blob-2.svg';

const Hero: React.FC = () => {
  return (
    <Container className={`p-xl-0 p-lg-0 ${styles.heroWrap}`} fluid>
      <Row className={`m-0 ${styles.heroBanner}`}>
        <div className={styles.heroBannerImage}>
          <img
            src={HeroBanner}
            alt="Join one of the biggest music career job boards"
          />
        </div>
        <Col>
          <div className={styles.heroBubble}>
            <div className={styles.heroBubbleData}>
              <div className={styles.heroTitle}>
                Welcome to your music professional network.
              </div>
              <div className={styles.heroDesc}>
                Network, collaborate, find jobs and so much more.
              </div>
              {/* <div className={styles.heroLink}>View all Job Opportunities</div> */}
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col
          className={`${styles.heroBlock} ${styles.heroBlockOpportunities}`}
          xl={6}
          lg={6}
          md={12}
          sm={12}
        >
          <div className={styles.heroBlockWrap}>
            <Row>
              <Col xl={8} lg={8} md={12} sm={12}>
                <div className={styles.heroBlockLabel}>Opportunities</div>
                <div className={styles.heroBlockTitle}>
                  Want to build your network?
                </div>
                <div className={styles.heroBlockDesc}>
                  Find musicians for collaboration, service professionals and
                  more.
                </div>
              </Col>
              <Col className={styles.heroIconCol} xl={4} lg={4} md={12} sm={12}>
                <div className={styles.heroBlockIconWrap}>
                  <SVG
                    baseURL="/"
                    cacheRequests={true}
                    className={styles.heroBlockIcon}
                    description="Opportunities"
                    loader={<span>Loading...</span>}
                    src={Gesture}
                  />
                  <SVG
                    baseURL="/"
                    cacheRequests={true}
                    className={styles.heroBlockIconBg}
                    description="Opportunities BG"
                    loader={<span>Loading...</span>}
                    src={Blob}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col
          className={`${styles.heroBlock} ${styles.heroBlockCareers}`}
          xl={6}
          lg={6}
          md={12}
          sm={12}
        >
          <div className={styles.heroBlockWrap}>
            <Row>
              <Col xl={8} lg={8} md={12} sm={12}>
                <div className={styles.heroBlockLabel}>Careers</div>
                <div className={styles.heroBlockTitle}>
                  Looking for a new career?
                </div>
                <div className={styles.heroBlockDesc}>
                  Everything from full time to contract work, internships and
                  more.
                </div>
              </Col>
              <Col className={styles.heroIconCol} xl={4} lg={4} md={12} sm={12}>
                <div className={styles.heroBlockIconWrap}>
                  <SVG
                    baseURL="/"
                    cacheRequests={true}
                    className={styles.heroBlockIcon}
                    description="Careers"
                    loader={<span>Loading...</span>}
                    src={Studio}
                  />
                  <SVG
                    baseURL="/"
                    cacheRequests={true}
                    className={styles.heroBlockIconBg}
                    description="Careers BG"
                    loader={<span>Loading...</span>}
                    src={Blob2}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(Hero);
