import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Redirect } from 'react-router-dom';

import jwt from 'jwt-decode';

// RegistrationForm and UserDetailsForm components
import RegisterForm from './RegisterForm/RegisterForm';
import UserDetailsForm from './UserDetailsForm/UserDetailsForm';

// Constants
import {
  APP_CONSTANTS as Constants,
  API_ENDPOINTS,
  SECRET_API_KEY,
  ROUTES,
} from 'constants/Constants';

// Services
import { registerUserService } from 'services/UserService';
import {
  getUploadURLService,
  fetchCategoriesService,
} from 'services/CommonService';

// Types
import { RegisterFormType, UserDetailsType } from 'interfaces/Register.types';

// Context
import GlobalContext from 'contexts/Global.context';

// Utils
import { camelToSnake, validateURL } from 'utils/UtilityFunctions';
import { Category } from 'interfaces/Category.types';

interface APIResponse {
  token: string;
  id: string;
}

const {
  errorConstants: {
    EMAIL_IS_NOT_VALID,
    EMAIL_NOT_ENTERED_ERROR,
    USER_NAME_NOT_ENTERED,
    USER_NAME_INVALID,
    PASSWORD_CHAR_LENGTH_ERROR,
    PASSWORD_NOT_ENTERED_ERROR,
    CONFIRM_PASSWORD_ERROR,
    PASSWORDS_MISMATCH_ERROR,
  },

  regExValidators: { EMAIL_VALIDATOR_REGEX, USER_NAME_VALIDATOR },
} = Constants;

const USER_INFO_ERRORS: any = {
  firstName: 'Please enter your first name',
  lastName: 'Please enter your last name',
  bio: 'Please enter personal bio',
  /* about: 'Please enter something about yourself', */
  city: 'Please enter the city',
  state: 'Please enter the state',
  zip: 'Please enter the zip code',
  website: 'Please enter a valid website URL',
};

const USER_REGISTER_VALIDATIONS: any = {
  userName: {
    min: 1,
    max: 30,
    error: 'Please enter characters between 1 and 30',
  },
  password: {
    min: 8,
    max: 50,
    error: 'Please enter characters between 1 and 20',
  },
};

const USER_DETAILS_VALIDATIONS: any = {
  firstName: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 20',
  },
  lastName: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 20',
  },
  userName: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 30',
  },
  bio: {
    min: 1,
    max: 5000,
    error: 'Please enter characters between 1 and 300',
  },
  /* about: {
    min: 1,
    max: 300,
    error: 'Please enter characters between 1 and 300',
  }, */
  city: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 20',
  },
  state: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 20',
  },
  zip: {
    min: 5,
    max: 6,
    error: 'Please enter characters between 5 and 6',
  },
};

const Register: React.FunctionComponent = () => {
  const { setAuth, isLoggedIn, userDetails: storedUser } = useContext(
    GlobalContext
  );

  const [step, setStep] = useState<number>(1);

  const [registerFormData, setRegisterFormData] = useState<RegisterFormType>({
    email: '',
    userName: '',
    password: '',
  });

  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [registerFormErrors, setRegisterFormErrors] = useState<
    RegisterFormType | any
  >({
    email: '',
    userName: '',
    password: '',
    confirmPassword: '',
  });

  const [validating, setValidating] = useState<boolean>(false);
  const [validateApiError, setValidateApiError] = useState<string>('');

  const [userDetails, setUserDetails] = useState<UserDetailsType>({
    firstName: '',
    lastName: '',
    bio: '',
    /* about: '', */
    careerCategories: '',
    opportunityCategories: '',
    city: '',
    state: '',
    zip: '',
    website: '',
    facebook: '',
    twitter: '',
    youtube: '',
    instagram: '',
    additionalLink1: { title: '', link: '' },
    additionalLink2: { title: '', link: '' },
  });

  const [photo, setPhoto] = useState<null | File>(null);

  const [resumeFile, setResumeFile] = useState<null | File>(null);

  const [userDetailsErrors, setUserDetailsErrors] = useState<any>({
    firstName: '',
    lastName: '',
    bio: '',
    /* about: '', */
    city: '',
    state: '',
    zip: '',
    additionalLink1Title: '',
    additionalLink2Title: '',
    additionalLink1: '',
    additionalLink2: '',
    /* additionalLinks: ['', ''], */
  });

  const [registerLoading, setRegisterLoading] = useState<boolean>(false);
  const [registerApiError, setRegisterApiError] = useState<string>('');
  const [userId, setUserId] = useState<string>('');

  const [categories, setCategories] = useState<Array<Category | any>>([]);

  /**
   * Handler that gets called when the text in input field changes
   * @param {Object} event The event object
   */
  const handleRegisterInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    if (name === 'email') {
      if (!value) {
        setRegisterFormErrors({
          ...registerFormErrors,
          [name]: EMAIL_NOT_ENTERED_ERROR,
        });
      } else {
        if (!EMAIL_VALIDATOR_REGEX.test(value)) {
          setRegisterFormErrors({
            ...registerFormErrors,
            [name]: EMAIL_IS_NOT_VALID,
          });
        } else {
          setRegisterFormErrors({
            ...registerFormErrors,
            [name]: '',
          });
        }
      }
    }

    if (name === 'userName') {
      if (!value) {
        setRegisterFormErrors({
          ...registerFormErrors,
          [name]: USER_NAME_NOT_ENTERED,
        });
      } else {
        if (!USER_NAME_VALIDATOR.test(value)) {
          setRegisterFormErrors({
            ...registerFormErrors,
            [name]: USER_NAME_INVALID,
          });
        } else {
          if (
            USER_REGISTER_VALIDATIONS[name] &&
            value.length > USER_REGISTER_VALIDATIONS[name].max
          ) {
            setRegisterFormErrors({
              ...registerFormErrors,
              [name]: USER_REGISTER_VALIDATIONS[name].error,
            });
          } else {
            setRegisterFormErrors({
              ...registerFormErrors,
              [name]: '',
            });
          }
        }
      }
    }

    if (name === 'password') {
      if (!value) {
        setRegisterFormErrors({
          ...registerFormErrors,
          [name]: PASSWORD_NOT_ENTERED_ERROR,
        });
      } else {
        if (value.length < 8) {
          setRegisterFormErrors({
            ...registerFormErrors,
            [name]: PASSWORD_CHAR_LENGTH_ERROR,
          });
        } else {
          setRegisterFormErrors({
            ...registerFormErrors,
            [name]: '',
          });
        }
      }
    }

    if (name === 'confirmPassword') {
      if (!value) {
        setRegisterFormErrors({
          ...registerFormErrors,
          [name]: CONFIRM_PASSWORD_ERROR,
        });
      } else {
        if (value !== registerFormData.password) {
          setRegisterFormErrors({
            ...registerFormErrors,
            [name]: PASSWORDS_MISMATCH_ERROR,
          });
        } else {
          setRegisterFormErrors({
            ...registerFormErrors,
            [name]: '',
          });
        }
      }
    }

    if (name !== 'confirmPassword') {
      setRegisterFormData({
        ...registerFormData,
        [name]: value,
      });
    } else {
      setConfirmPassword(value);
    }
  };

  const uploadPhoto = async () => {
    let uploadImageResponse: any = null;
    let errorOccurred: any = null;
    if (photo) {
      const fileInfo = {
        name: photo.name,
        type: photo.type,
      };
      const { signedRequest, url, error } = await getUploadURLService(fileInfo);

      if (signedRequest && url) {
        uploadImageResponse = await fetch(signedRequest, {
          method: 'PUT',
          body: photo,
        });

        if (uploadImageResponse) {
          setUserDetails({
            ...userDetails,
            photo: url,
          });
        }
        return { uploadImageResponse, url };
      } else if (error) {
        setRegisterApiError(
          `An error occurred while uploading photo: ${error}`
        );
        errorOccurred = error;
        return { error };
      }
    }
    return { uploadImageResponse, error: errorOccurred };
  };

  const uploadResume = async () => {
    if (resumeFile) {
      const fileInfo = {
        name: resumeFile.name,
        type: resumeFile.type,
      };
      const { signedRequest, url, error } = await getUploadURLService(fileInfo);

      if (signedRequest && url) {
        const uploadResumeResponse = await fetch(signedRequest, {
          method: 'PUT',
          body: resumeFile,
        });

        if (uploadResumeResponse) {
          setUserDetails({
            ...userDetails,
            photo: url,
          });
        }
        return { uploadResumeResponse, url };
      } else if (error) {
        setRegisterApiError(
          `An error occurred while uploading photo: ${error}`
        );
        return { error };
      }
    }
  };

  const callAnalytics = (token: string) => {
    const user: any = jwt(token);
    window.analytics.identify({
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      username: user.user_name,
      id: user.id,
      userId: user.id,
    });
  };

  const registration = async (photoURL?: string, resumeURL?: string) => {
    setRegisterLoading(true);
    const consolidatedRequestBody: any = {
      ...registerFormData,
      ...userDetails,
    };
    const reducer = (acc: any, current: string) => {
      const newObj: any = {};
      if (current !== 'additionalLink1' && current !== 'additionalLink2') {
        newObj[camelToSnake(current)] = consolidatedRequestBody[current];
      }
      return { ...acc, ...newObj };
    };
    const requestBody = Object.keys({
      ...registerFormData,
      ...userDetails,
    }).reduce(reducer, {});
    if (!userDetails.additionalLink1 && !userDetails.additionalLink2) {
      requestBody.additional_links = [];
    } else {
      const links = [userDetails.additionalLink1, userDetails.additionalLink2];
      requestBody.additional_links = links.filter((l) => l.title && l.link);
      requestBody.additional_links = requestBody.additional_links.map(
        (item) => ({
          title: item.title,
          link: item.link.toLowerCase(),
        })
      );
    }

    if (requestBody.twitter) {
      requestBody.twitter = requestBody.twitter.toLowerCase();
    }

    if (requestBody.website) {
      requestBody.website = requestBody.website.toLowerCase();
    }

    if (requestBody.youtube) {
      requestBody.youtube = requestBody.youtube.toLowerCase();
    }

    if (requestBody.facebook) {
      requestBody.facebook = requestBody.facebook.toLowerCase();
    }

    if (requestBody.instagram) {
      requestBody.instagram = requestBody.instagram.toLowerCase();
    }

    const response = await registerUserService({
      ...requestBody,
      photo: photoURL,
      resume: resumeURL,
    });

    if (!response.ok) {
      const error = await response.clone().text();
      setRegisterApiError(error);
    } else {
      const { token, id } = await response.json();
      callAnalytics(token);
      setAuth(token);
      setUserId(id);
    }
    setRegisterLoading(false);
  };

  /**
   * The below function gets called once the user submits the form
   */
  const registerUser = async () => {
    setRegisterApiError('');
    setRegisterLoading(true);
    if (photo && resumeFile) {
      // first try uploading photo and then upload resume
      const {
        uploadImageResponse,
        url: photoUrl,
        error,
      }: any = await uploadPhoto();
      if (uploadImageResponse) {
        const {
          uploadResumeResponse,
          url: resumeUrl,
          error,
        }: any = await uploadResume();
        if (uploadResumeResponse) {
          await registration(photoUrl, resumeUrl);
        } else if (error) {
          return;
        }
      } else if (error) {
        return;
      }
    } else if (photo && !resumeFile) {
      const { uploadImageResponse, url: photoUrl }: any = await uploadPhoto();
      if (uploadImageResponse) {
        await registration(photoUrl, '');
      }
    } else if (!photo && resumeFile) {
      const {
        uploadResumeResponse,
        url: resumeUrl,
      }: any = await uploadResume();
      if (uploadResumeResponse) {
        await registration('', resumeUrl);
      }
    } else {
      await registration('', '');
    }

    setRegisterLoading(false);
  };

  /**
   * Function to disable/enable the submit button
   */
  const isNextStepDisabled = (): boolean => {
    const { email, userName, password } = registerFormData;

    if (
      email &&
      EMAIL_VALIDATOR_REGEX.test(email) &&
      userName &&
      USER_NAME_VALIDATOR.test(userName) &&
      password &&
      password.length >= 8 &&
      confirmPassword &&
      confirmPassword === password &&
      Object.keys(registerFormErrors).every((e) => !registerFormErrors[e])
    ) {
      return false;
    }

    return true;
  };

  /**
   *
   * @param {Object} errors The form errors
   */
  const validateForm = (errors: UserDetailsType | RegisterFormType) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  /**
   * @param {Object} errorObject Error object from API
   * Create an error message from error Object received from the API
   */
  const createAnErrorMessage = (errorObject: any) => {
    const reducer = (acc: string, currentVal: string) =>
      `${errorObject[currentVal]} ${acc}`;
    return Object.keys(errorObject).reduce(reducer, '');
  };

  /**
   * The below method checks whether the userName and email ID entered
   * by user are unique by making a call to the backend
   */
  const validateCredentials = async () => {
    setValidateApiError('');
    setValidating(true);
    const response = await fetch(API_ENDPOINTS.VALIDATE_REGISTRATION_DATA, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
        'x-api-key': SECRET_API_KEY,
      },
      method: 'POST',
      body: JSON.stringify({
        user_name: registerFormData.userName,
        email: registerFormData.email,
        password: registerFormData.password,
      }),
    });

    if (!response.ok) {
      setValidateApiError(await response.clone().text());
      return false;
    } else {
      const credentialsError = await response.json();
      const { user_name, email, password } = credentialsError;
      if (!user_name && !email && !password) {
        return true;
      } else {
        setValidateApiError(createAnErrorMessage(credentialsError));
      }
    }
    setValidating(false);
    return false;
  };

  const goToNextStep = async () => {
    if (validateForm(registerFormErrors)) {
      const isValidData = await validateCredentials();
      if (isValidData) {
        setStep(2);
      }
    }
  };

  /**
   * Handler that gets called when the text in input field changes
   * @param {Object} event The event object
   */
  const handleUserDetailsInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    if (!value) {
      setUserDetailsErrors({
        ...userDetailsErrors,
        [name]: USER_INFO_ERRORS[name],
      });
    } else {
      if (USER_DETAILS_VALIDATIONS[name]) {
        if (
          value.length > USER_DETAILS_VALIDATIONS[name].max ||
          value.length < USER_DETAILS_VALIDATIONS[name].min
        ) {
          setUserDetailsErrors({
            ...userDetailsErrors,
            [name]: USER_DETAILS_VALIDATIONS[name].error,
          });
        } else {
          setUserDetailsErrors({
            ...userDetailsErrors,
            [name]: '',
          });
        }
      } else {
        setUserDetailsErrors({
          ...userDetailsErrors,
          [name]: '',
        });
      }
    }

    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleCategorySelect = (categoryList: Array<Category>, key: string) => {
    const selectedCategories = categoryList.map((list) => list.name);

    setUserDetails({
      ...userDetails,
      [key]: selectedCategories.join(','),
    });
  };

  /**
   * Handler that gets called when the text in website/social links input field is changed
   * @param {Object} event The event object
   */
  const handleWebsiteInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    if (value) {
      if (validateURL(value)) {
        setUserDetailsErrors({
          ...userDetailsErrors,
          [name]: '',
        });
      } else {
        setUserDetailsErrors({
          ...userDetailsErrors,
          [name]: USER_INFO_ERRORS['website'],
        });
      }
    } else {
      setUserDetailsErrors({
        ...userDetailsErrors,
        [name]: '',
      });
    }

    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handlePhotoChange = (image: File | null) => {
    setPhoto(image);
  };

  const handleResumeChange = (resume: File | null) => {
    setResumeFile(resume);
  };

  const handleAdditionalLinksChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    if (name === 'additionalLink1Title') {
      if (value) {
        if (!userDetails.additionalLink1.link) {
          setUserDetailsErrors({
            ...userDetailsErrors,
            additionalLink1: 'Please enter URL',
          });
        } else {
          setUserDetailsErrors({
            ...userDetailsErrors,
            additionalLink1: validateURL(userDetails.additionalLink1.link)
              ? ''
              : USER_INFO_ERRORS['website'],
            additionalLink1Title: '',
          });
        }
      } else {
        if (!userDetails.additionalLink1.link) {
          setUserDetailsErrors({
            ...userDetailsErrors,
            additionalLink1: '',
            additionalLink1Title: '',
          });
        } else {
          setUserDetailsErrors({
            ...userDetailsErrors,
            additionalLink1: validateURL(userDetails.additionalLink1.link)
              ? ''
              : USER_INFO_ERRORS['website'],
            additionalLink1Title: 'Please enter title',
          });
        }
      }

      setUserDetails({
        ...userDetails,
        additionalLink1: {
          ...userDetails.additionalLink1,
          title: value,
        },
      });
    }

    if (name === 'additionalLink1') {
      if (value) {
        if (!userDetails.additionalLink1.title) {
          setUserDetailsErrors({
            ...userDetailsErrors,
            additionalLink1Title: 'Please enter title',
            additionalLink1: validateURL(value)
              ? ''
              : USER_INFO_ERRORS['website'],
          });
        } else {
          setUserDetailsErrors({
            ...userDetailsErrors,
            additionalLink1Title: '',
            additionalLink1: validateURL(value)
              ? ''
              : USER_INFO_ERRORS['website'],
          });
        }
      } else {
        if (!userDetails.additionalLink1.title) {
          setUserDetailsErrors({
            ...userDetailsErrors,
            additionalLink1Title: '',
            additionalLink1: '',
          });
        } else {
          setUserDetailsErrors({
            ...userDetailsErrors,
            additionalLink1Title: '',
            additionalLink1: 'Please enter URL',
          });
        }
      }

      setUserDetails({
        ...userDetails,
        additionalLink1: {
          ...userDetails.additionalLink1,
          link: value,
        },
      });
    }

    if (name === 'additionalLink2Title') {
      if (value) {
        if (!userDetails.additionalLink2.link) {
          setUserDetailsErrors({
            ...userDetailsErrors,
            additionalLink2: 'Please enter URL',
          });
        } else {
          setUserDetailsErrors({
            ...userDetailsErrors,
            additionalLink2: validateURL(userDetails.additionalLink2.link)
              ? ''
              : USER_INFO_ERRORS['website'],
            additionalLink2Title: '',
          });
        }
      } else {
        if (!userDetails.additionalLink2.link) {
          setUserDetailsErrors({
            ...userDetailsErrors,
            additionalLink2: '',
            additionalLink2Title: '',
          });
        } else {
          setUserDetailsErrors({
            ...userDetailsErrors,
            additionalLink2: validateURL(userDetails.additionalLink2.link)
              ? ''
              : USER_INFO_ERRORS['website'],
            additionalLink2Title: 'Please enter title',
          });
        }
      }

      setUserDetails({
        ...userDetails,
        additionalLink2: {
          ...userDetails.additionalLink2,
          title: value,
        },
      });
    }

    if (name === 'additionalLink2') {
      if (value) {
        if (!userDetails.additionalLink2.title) {
          setUserDetailsErrors({
            ...userDetailsErrors,
            additionalLink2Title: 'Please enter title',
            additionalLink2: validateURL(value)
              ? ''
              : USER_INFO_ERRORS['website'],
          });
        } else {
          setUserDetailsErrors({
            ...userDetailsErrors,
            additionalLink2Title: '',
            additionalLink2: validateURL(value)
              ? ''
              : USER_INFO_ERRORS['website'],
          });
        }
      } else {
        if (!userDetails.additionalLink2.title) {
          setUserDetailsErrors({
            ...userDetailsErrors,
            additionalLink2Title: '',
            additionalLink2: '',
          });
        } else {
          setUserDetailsErrors({
            ...userDetailsErrors,
            additionalLink2Title: '',
            additionalLink2: 'Please enter URL',
          });
        }
      }

      setUserDetails({
        ...userDetails,
        additionalLink2: {
          ...userDetails.additionalLink2,
          link: value,
        },
      });
    }
  };

  /**
   * Function to disable/enable the submit button
   */
  const isSubmitDisabled = (): boolean => {
    const { firstName, lastName, bio, city, state, zip } = userDetails;

    if (
      firstName &&
      lastName &&
      bio &&
      /* about && */
      city &&
      state &&
      zip &&
      Object.keys(userDetailsErrors).every((e) => !userDetailsErrors[e])
    ) {
      return false;
    }

    return true;
  };

  /**
   * The function gets called when user submits the form or click on submit button
   */
  const handleSubmit = () => {
    if (validateForm(userDetailsErrors)) {
      registerUser();
    }
  };

  const fetchCategories = useCallback(async () => {
    const categories: Array<Category> = await fetchCategoriesService();

    if (categories && categories.length) {
      setCategories(categories);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  if (isLoggedIn && userId) {
    return <Redirect to={`${ROUTES.PROFILE}/${storedUser.slug}?confirm=1`} />;
  }

  const renderRegistrationForm = () => {
    return (
      <RegisterForm
        registerFormData={{ ...registerFormData, confirmPassword }}
        registerFormErrors={registerFormErrors}
        handleInputChange={handleRegisterInputChange}
        isNextStepDisabled={isNextStepDisabled()}
        loading={validating}
        apiError={validateApiError}
        goToNextStep={goToNextStep}
      />
    );
  };

  const renderUserDetailsForm = () => {
    return (
      <UserDetailsForm
        categories={categories}
        userDetails={userDetails}
        userDetailsErrors={userDetailsErrors}
        handleInputChange={handleUserDetailsInputChange}
        handleCategorySelect={handleCategorySelect}
        handleWebsiteInputChange={handleWebsiteInputChange}
        handleAdditionalLinksChange={handleAdditionalLinksChange}
        handlePhotoChange={handlePhotoChange}
        handleResumeChange={handleResumeChange}
        isSubmitDisabled={isSubmitDisabled()}
        loading={registerLoading}
        apiError={registerApiError}
        handleSubmit={handleSubmit}
      />
    );
  };

  const pageToBeShown = () => {
    switch (step) {
      case 1:
        return renderRegistrationForm();

      case 2:
        return renderUserDetailsForm();

      default:
        return renderRegistrationForm();
    }
  };

  return <>{pageToBeShown()}</>;
};

export default React.memo(Register);
