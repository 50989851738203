import React, { useContext, useEffect } from 'react';

import { withRouter } from 'react-router-dom';

import Header from './components/Header/Header';

// Context
import GlobalContext from './contexts/Global.context';

// Components
import ErrorBoundary from './ErrorBoundary';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';

// Routes
import AppRoutes from './AppRoutes';

// Services
import { authenticateLoginService } from 'services/CommonService';

// Styles
import './styles/mdr-base.scss';

declare global {
  interface Window {
    analytics: any;
  }
}

export const App = withRouter(({ location }) => {
  const path = location.pathname;
  const alternateHeader = path !== '/career/id';

  const { isLoggedIn, logout } = useContext(GlobalContext);

  useEffect(() => {
    const authenticateLogin = async () => {
      const response = await authenticateLoginService();
      if (!response.ok) {
        const { status } = response;
        if (status === 401) {
          logout();
        }
      }
    };
    if (isLoggedIn) {
      authenticateLogin();
    }
  }, [isLoggedIn, logout]);

  return (
    <ErrorBoundary>
      <Container className="p-0" fluid>
        <Helmet>
          <title>Music Industry Careers</title>
        </Helmet>
        {alternateHeader ? <Header /> : <Header alt />}
        <AppRoutes />
      </Container>
    </ErrorBoundary>
  );
});
