import { APP_CONSTANTS } from 'constants/Constants';

const {
  regExValidators: { URL_VALIDATOR, ZIP_VALIDATOR },
} = APP_CONSTANTS;

interface Dictionary<T> {
  [key: string]: T;
}

const CAREER_RULES: Dictionary<any> = {
  title: {
    required: true,
    minLength: 2,
    maxLength: 100,
  },
  category: {
    required: true,
  },
  pay: {
    min: 0,
  },
  deadline: {
    required: false,
  },
  rate: {
    required: true,
  },
  isFeatured: {
    required: true,
  },
  expectedNeed: {
    required: true,
  },
  location: {
    required: true,
  },
  companyName: {
    required: true,
    minLength: 1,
    maxLength: 20,
  },
  zip: {
    required: true,
    minLength: 5,
    maxLength: 5,
    pattern: ZIP_VALIDATOR,
  },
  state: {
    required: true,
  },
  city: {
    required: true,
    minLength: 1,
    maxLength: 20,
  },
  opportunityDescription: {
    required: true,
    minLength: 1,
  },
  hiringDescription: {
    required: true,
    minLength: 1,
  },
  applyThrough: {
    required: true,
  },
  externalUrl: {
    pattern: URL_VALIDATOR,
  },
};

export default CAREER_RULES;
