import React from 'react';
import Search from './Search';

// Constants
import { JOB_TYPE } from 'constants/Constants';

const Careers: React.FC = () => {
  return (
    <Search
      title="Careers"
      type={JOB_TYPE.career}
      subtitle="Everything from full time to contract work, internships and more."
    />
  );
};

export default React.memo(Careers);
