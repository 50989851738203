import React from 'react';
import Search from './Search';

// Constants
import { JOB_TYPE } from 'constants/Constants';

const Opportunities: React.FC = () => {
  return (
    <Search
      title="Opportunities"
      type={JOB_TYPE.opportunity}
      subtitle="Find musicians for collaboration, service professionals, and more."
    />
  );
};

export default React.memo(Opportunities);
