export const DEFAULT_CAREER_ERRORS = {
  title: '',
  category: '',
  rate: '',
  expectedNeed: '',
  location: '',
  companyName: '',
  zip: '',
  state: '',
  city: '',
  opportunityDescription: '',
  hiringDescription: '',
  applyThrough: '',
  externalUrl: '',
  deadline: '',
  pay: '',
  isFeatured: '',
};

export const DEFAULT_CAREER_VALUES = {
  type: '',
  title: '',
  category: [],
  pay: '',
  rate: '',
  expectedNeed: '',
  location: '',
  companyName: '',
  companyLogo: '',
  deadline: '',
  zip: '',
  state: '',
  city: '',
  opportunityDescription: '',
  hiringDescription: '',
  applyThrough: '',
  externalUrl: '',
  isFeatured: null as null,
};

export const DEFAULT_OPPORTUNITY_ERRORS = {
  title: '',
  category: '',
  rate: '',
  expectedNeed: '',
  location: '',
  companyName: '',
  zip: '',
  state: '',
  city: '',
  opportunityDescription: '',
  hiringDescription: '',
  applyThrough: '',
  externalUrl: '',
  pay: '',
  fee: '',
  referenceLinks: ['', '', ''],
  isFeatured: '',
};

export const DEFAULT_OPPORTUNITY_VALUES = {
  type: '',
  title: '',
  category: [],
  pay: '',
  rate: '',
  expectedNeed: '',
  location: '',
  companyName: '',
  companyLogo: '',
  zip: '',
  state: '',
  city: '',
  opportunityDescription: '',
  hiringDescription: '',
  fee: '',
  applyThrough: '',
  externalUrl: '',
  referenceLinks: ['', '', ''],
  isFeatured: null as null,
};
