interface ApiConstants {
  API_URL: string;
}

interface ErrorConstants {
  EMAIL_NOT_ENTERED_ERROR: string;
  EMAIL_IS_NOT_VALID: string;
  PASSWORD_NOT_ENTERED_ERROR: string;
  PASSWORD_CHAR_LENGTH_ERROR: string;
  CONFIRM_PASSWORD_ERROR: string;
  PASSWORDS_MISMATCH_ERROR: string;
  FISRT_NAME_NOT_ENTERED: string;
  LAST_NAME_NOT_ENTERED: string;
  USER_NAME_NOT_ENTERED: string;
  USER_NAME_INVALID: string;
  SLUG_INVALID: string;
  SOMETHING_WENT_WRONG: string;
}

interface RegExValidators {
  EMAIL_VALIDATOR_REGEX: RegExp;
  USER_NAME_VALIDATOR: RegExp;
  URL_VALIDATOR: RegExp;
  ZIP_VALIDATOR: RegExp;
}

interface PageConstants {
  ITEMS_PER_PAGE: number;
}

interface JobConstants {
  JOB_SUBMIT_SUCCESS: string;
  JOB_UPDATE_SUCCESS: string;
}

interface Constants {
  apiConstants: ApiConstants;
  errorConstants: ErrorConstants;
  regExValidators: RegExValidators;
  pageConstants: PageConstants;
  jobConstants: JobConstants;
  stripeKey: string;
  featuredAmount: number;
  postCost: number;
}

export const BASE_URL: string = 'https://api.musicindustry.careers';

export const APP_CONSTANTS: Constants = {
  apiConstants: {
    API_URL: BASE_URL,
  },
  errorConstants: {
    EMAIL_NOT_ENTERED_ERROR: 'Please enter an email address',
    EMAIL_IS_NOT_VALID: 'Please enter a valid email address',
    PASSWORD_NOT_ENTERED_ERROR: 'Please enter your password',
    PASSWORD_CHAR_LENGTH_ERROR:
      'Please enter minimum 8 characters in the password',
    CONFIRM_PASSWORD_ERROR: 'Please enter confirm password',
    PASSWORDS_MISMATCH_ERROR: 'Passwords do not match',
    FISRT_NAME_NOT_ENTERED: 'Please enter your first name',
    LAST_NAME_NOT_ENTERED: 'Please enter your last name',
    USER_NAME_NOT_ENTERED: 'Please enter your user name',
    USER_NAME_INVALID: 'Accepted characters are A-Z, a-z, 0-9, _, -',
    SLUG_INVALID: 'Accepted characters are A-Z, a-z, 0-9, _, -',
    SOMETHING_WENT_WRONG: 'Something went wrong, please try again.',
  },
  regExValidators: {
    //eslint-disable-next-line
    EMAIL_VALIDATOR_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    USER_NAME_VALIDATOR: /^[a-zA-Z0-9-_]+$/,
    URL_VALIDATOR: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i,
    ZIP_VALIDATOR: /^\d{1,5}$/,
  },
  pageConstants: {
    ITEMS_PER_PAGE: 15,
  },
  jobConstants: {
    JOB_SUBMIT_SUCCESS: 'Job posted successfully.',
    JOB_UPDATE_SUCCESS: 'Job updated successfully.',
  },
  stripeKey:
    'pk_live_51H9bdSEGEDgwNG44hFQ4IEMQIfSpQsliyER5oa4oofuFNiJwn4nzMS7CTYqEcdZ9tSYQs6pQBhAezErtiCNhbpyo00ObF2fusw',
  featuredAmount: 19.99,
  postCost: 10,
};

/**
 * Constants for route paths
 */
export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  ABOUT: '/about',
  TERMS: '/terms',
  PRIVACY: '/privacy',
  SUPPORT: '/support',
  ACCOUNT_VERIFY: '/account-verify',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token',
  DASHBOARD: '/dashboard',
  CAREERS: '/careers',
  OPPORTUNITIES: '/opportunities',
  FIND_SOMEONE: '/find-someone',
  EDIT_POST: '/edit-post/:type/:id',
  CAREER: '/career',
  OPPORTUNITY: '/opportunity',
  CAREER_DETAIL: '/career/:id',
  OPPORTUNITY_DETAIL: '/opportunity/:id',
  JOB_DETAIL: '/career/:id',

  // Dashboard nested routes
  CAREERS_APPLIED: '/careers-applied',
  CAREERS_POSTED: '/careers-posted',
  ARCHIVED_CAREERS: '/archived-careers',
  CAREERS_PENDING_APPLICANTS: '/careers-pending-applicants',

  OPPORTUNITIES_APPLIED: '/opportunities-applied',
  OPPORTUNITIES_POSTED: '/opportunities-posted',
  ARCHIVED_OPPORTUNITIES: '/archived-opportunities',
  OPPORTUNITIES_PENDING_APPLICANTS: '/opportunities-pending-applicants',

  MY_MESSAGES: '/messages',
  EDIT_PROFILE: '/edit-profile',
  MY_RESUME: '/resume',
  EDIT_ACCOUNT_INFO: '/edit-account-info',

  PROFILE: '/profile',
  APPLICANT_PROFILE: '/profile/:slug',
};

export const API_TYPE = {
  VALIDATE_TOKEN: 'authorize',
  USERS: 'users',
  VALIDATE_CREDENTIALS: 'users/validate-credentials',
  LOGIN: 'login',
  JOBS: 'jobs',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  APPLICATIONS: 'applications',
  GET_UPLOAD_URL: 'get-upload-url',
  MESSAGES: 'messages',
  CARDS: 'cards',
  VERIFY_COUPON: 'coupons/verify',
  FETCH_CATEGORIES: 'categories',
};

/**
 * Constants for Backend API Endpoints
 */
export const API_ENDPOINTS = {
  // User related enpoints
  VALIDATE_TOKEN: `${BASE_URL}/${API_TYPE.VALIDATE_TOKEN}`,
  VALIDATE_REGISTRATION_DATA: `${BASE_URL}/${API_TYPE.VALIDATE_CREDENTIALS}`,
  USERS: `${BASE_URL}/${API_TYPE.USERS}`,
  LOGIN: `${BASE_URL}/${API_TYPE.LOGIN}`,
  FORGOT_PASSWORD: `${BASE_URL}/${API_TYPE.FORGOT_PASSWORD}`,
  RESET_PASSWORD: `${BASE_URL}/${API_TYPE.RESET_PASSWORD}`,

  GET_UPLOAD_URL: `${BASE_URL}/${API_TYPE.GET_UPLOAD_URL}`,
  JOBS: `${BASE_URL}/${API_TYPE.JOBS}`,
  OPPORTUNITIES: `${BASE_URL}/${API_TYPE.JOBS}/list`,
  APPLY_FOR_JOB: `${BASE_URL}/${API_TYPE.APPLICATIONS}`,

  APPLIED_JOBS: `${BASE_URL}/${API_TYPE.JOBS}/user`,

  PENDING_APPLICATIONS: `${BASE_URL}/${API_TYPE.APPLICATIONS}/pending`,

  USER_PROFILE: `${BASE_URL}/profile`,

  MESSAGES: `${BASE_URL}/${API_TYPE.MESSAGES}`,

  CARDS: `${BASE_URL}/${API_TYPE.CARDS}`,

  VERIFY_COUPON: `${BASE_URL}/${API_TYPE.VERIFY_COUPON}`,

  FETCH_CATEGORIES: `${BASE_URL}/${API_TYPE.FETCH_CATEGORIES}`,

  REPORT_USER: `${BASE_URL}/${API_TYPE.USERS}/report`,

  CERTIFICATES: `${BASE_URL}/certificates`,
};

// Secret api key to access the backend endpoints
export const SECRET_API_KEY = 'MdRJ08K0@';

export const FILE_TYPE = {
  IMAGE: 'image',
  DOCUMENT: 'document',
};

export const ITEMS_PER_PAGE = 20;

export const DEFAULT_OFFSET = 0;

export const DEFAULT_PAGINATION_PARAMS = {
  limit: ITEMS_PER_PAGE,
  skip: DEFAULT_OFFSET,
};

export const JOB_TYPE = {
  career: 'career',
  opportunity: 'opportunity',
};

export const MIC_CONTACT_EMAIL = 'info@musiccareermap.com';
export const CLOUDINARY_BASE_URL =
  'https://res.cloudinary.com/careermap/image/upload';
