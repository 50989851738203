import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';

//Components
import Sidebar from '../Sidebar/Sidebar';

// SCSS
import styles from './Welcome.module.scss';

const Welcome = () => {
  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <div className={styles.mainHeading}>
            Welcome to <span>your music</span> professional network.
          </div>
          <p>
            Let’s face it: most career and opportunity marketplaces were not
            built with the music industry in mind. Too much of the focus is
            placed outside of our industry, resulting in not enough
            music-specific posts or the few posts offered being buried
            underneath everything else. We deserve a better way to find music
            industry careers and opportunities.{' '}
            <strong>
              This is exactly why we built Music Industry Careers.
            </strong>
          </p>
        </Col>
        <Col md={6}>
          <Sidebar />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.content}>
            <p>
              Music Industry Careers is a part of the Music Career Map family, a
              series of platforms built to help musicians, music professionals,
              educators and students gain access to the best education and
              career development resources.
            </p>

            <p>
              Music Career Map was developed by Brian Penick professional
              musician, entrepreneur, investors and writer with more than two
              decades of experience working in music, ranging from developing
              artists that have reached platinum status to launching music
              companies like The Counter Rhythm Group, Soundstr (acquired) and
              many more.
            </p>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Welcome;
