import React, { Fragment, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';

// Components
import Button from '../../../../components/Button/Button';

// Interfaces
import { ApplicantProfile } from 'interfaces/User.types';

// Context
import GlobalContext from 'contexts/Global.context';

// Constants
import { ROUTES, CLOUDINARY_BASE_URL } from 'constants/Constants';

// Styles
import styles from './Header.module.scss';

interface Props {
  profile: ApplicantProfile;
}

const Header: React.FC<Props> = ({ profile }: Props) => {
  const {
    userDetails: { id: loggedInUserId },
  } = useContext(GlobalContext);

  const clippedPhoto = (src: String) => {
    return `${CLOUDINARY_BASE_URL}/c_thumb,w_170,w_170,g_face,f_auto,z_0.6/s3/${src.substr(
      src.lastIndexOf('/') + 1
    )}`;
  };

  return (
    <Fragment>
      <Container className={styles.profileHeaderContainer}>
        <Row className="justify-content-md-center">
          <Col className={styles.p0}>
            <div className={styles.profileHeaderWrapper}>
              <div className={styles.imgWrapper}>
                <img
                  src={
                    clippedPhoto(profile.photo) ||
                    'https://placehold.co/170x170'
                  }
                  alt={profile.first_name}
                />
              </div>
              <div className={styles.userProfile}>
                <div className={styles.userName}>
                  {`${profile.first_name} ${profile.last_name}`}
                </div>
                <div className={styles.userLocation}>
                  <FontAwesomeIcon
                    icon={faMapMarker}
                    className={styles.mapIcon}
                  />
                  {`${profile.city}, ${profile.state}`}
                </div>
                {profile.id !== loggedInUserId ? (
                  <div className={styles.buttonWrapper}>
                    <Link
                      to={{
                        pathname: `${ROUTES.DASHBOARD}${ROUTES.MY_MESSAGES}/${profile.id}`,
                        state: { userName: profile.user_name },
                      }}
                    >
                      {' '}
                      <Button type="branding link" label="Message Me"></Button>
                    </Link>
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Header;
