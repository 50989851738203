import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// SCSS
import styles from './Support.module.scss';

const Support = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//musiciansdeskreference.activehosted.com/f/embed.php?id=130';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={styles.supportPage}>
      <Container className="p-0">
        <Row>
          <Col md={12}>
            <div className={styles.heading}>Support</div>
            <div className="_form_130"></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Support;
