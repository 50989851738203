import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Container, Row, Col } from 'react-bootstrap';
import Button from 'components/Button/Button';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

// Constants
import { ROUTES, JOB_TYPE } from 'constants/Constants';

// SCSS
import styles from './Result.module.scss';

// Props
interface Result {
  id?: string;
  image: string | 'https://placehold.co/70x70';
  title: string;
  category: string;
  location: string;
  type: string;
  jobType?: string;
  date: string;
  isFeatured?: boolean;
  city: string;
  state: string;
  isArchived?: boolean;
  isPosted?: boolean;
  /* deleteLoading?: boolean; */
  deleteAJobPosting?: (jobId: string) => void;
  /* relistLoading?: boolean; */
  relistAJobPosting?: (jobId: string) => void;
}

const Result = (props: Result) => {
  const [archiveLoading, setArchiveLoading] = useState<boolean>(false);

  const archiveAJobListing = async (jobId: string) => {
    setArchiveLoading(true);
    await props.deleteAJobPosting(jobId);
    setArchiveLoading(false);
  };

  let typeClass;

  let typeList = {
    full: ['full time', 'salary'],
    part: ['part time', 'freelance', 'internship'],
    one: ['one time'],
  };

  if (typeList.full.indexOf(props.type) >= 0) {
    typeClass = `${styles.fullTime}`;
  } else if (typeList.part.indexOf(props.type) >= 0) {
    typeClass = `${styles.partTime}`;
  } else if (typeList.one.indexOf(props.type) >= 0) {
    typeClass = `${styles.oneOff}`;
  } else {
    typeClass = 'unknown';
  }

  const jobExpiryDaysLeft = () => {
    const jobExpiryDate = moment(props.date).add(1, 'months');
    return jobExpiryDate.diff(moment(), 'days');
  };

  return (
    <Fragment>
      <Container
        className={`${styles.jobResult} ${
          props.isFeatured ? styles.featured : ''
        }`}
      >
        <Link
          to={
            props.jobType === JOB_TYPE.career
              ? `${ROUTES.CAREER}/${props.id}`
              : `${ROUTES.OPPORTUNITY}/${props.id}`
          }
        >
          <Row className={styles.resultDataRow}>
            <Col
              className={`p-0 col-1 ${styles.resultImageCol}`}
              xl={1}
              lg={1}
              md={1}
              sm={1}
            >
              <div className={styles.resultImage}>
                <img
                  className="img-fluid"
                  src={props.image}
                  alt="Lorem Ipsum"
                />
              </div>
            </Col>
            <Col
              className={`col-8 ${styles.resultInfo}`}
              xl={9}
              lg={9}
              md={9}
              sm={8}
            >
              {props.isFeatured ? (
                <div className={styles.featuredJob}>Featured</div>
              ) : null}
              <div className={styles.resultTitle}>{props.title}</div>
              <div className={styles.resultAttributes}>
                <span className={styles.resultCategory}>
                  <FontAwesomeIcon icon={faBuilding} />
                  &nbsp;
                  {props.category.split(',')[0]}
                </span>
                <span className={styles.resultLocation}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  &nbsp;
                  {`${props.city}, ${props.state}`}
                </span>
              </div>
            </Col>
            <Col
              className={`p-0 col-3 ${styles.resultAvailability}`}
              xl={2}
              lg={2}
              md={2}
              sm={3}
            >
              <div className={`${typeClass} ${styles.resultType}`}>
                {props.type || 'N/A'}
              </div>
              {props.isArchived ? (
                <div className={styles.resultTypeDate}>
                  {`Ended on: ${
                    props.date
                      ? moment(props.date).format('MM/DD/YY')
                      : moment().format('MM/DD/YY')
                  }`}
                </div>
              ) : (
                <div className={styles.resultTypeDate}>
                  {moment(props.date).fromNow()}
                </div>
              )}
            </Col>
          </Row>
        </Link>
      </Container>

      {props.isPosted ? (
        <Container className={styles.resultOptions}>
          <Row>
            <Col className={'p-0'} md={6} sm={12}>
              <Link to={`${ROUTES.CAREER}/${props.id}`} className="pl-2">
                <Button label="View Listing" type="transparent outline-gray" />
              </Link>
            </Col>
            <Col className={styles.optionsRight} md={6} sm={12}>
              <div className={styles.availabilityText}>
                Available for {jobExpiryDaysLeft()} more days
              </div>
              <Button
                label={archiveLoading ? 'Archiving...' : 'Archive'}
                type="light-pink"
                onClick={
                  archiveLoading ? () => {} : () => archiveAJobListing(props.id)
                }
              />
            </Col>
          </Row>
        </Container>
      ) : null}

      {props.isArchived ? (
        <Container className={styles.resultOptions}>
          <Row>
            <Col className={'p-0'} md={6} sm={12}>
              <Link
                to={{
                  pathname: ROUTES.FIND_SOMEONE,
                  search: `?id=${props.id}&type=${props.jobType}`,
                }}
                className="pl-2"
              >
                <Button label="Relist" type="transparent outline-gray" />
              </Link>
              <Link to={`${ROUTES.CAREER}/${props.id}`} className="pl-2">
                <Button label="View Listing" type="transparent outline-gray" />
              </Link>
            </Col>
          </Row>
        </Container>
      ) : null}
    </Fragment>
  );
};

export default React.memo(Result);
