import React from 'react';

import { Link } from 'react-router-dom';

import { Container, Row, Col, Nav } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faFacebookF,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

// Constants
import { ROUTES } from 'constants/Constants';

// SCSS
import './Footer.scss';

// SVGs
import Logo from '../../assets/svgs/footerlogo.svg';

const SOCIAL_LINKS = [
  {
    name: 'twitter',
    link: 'https://www.twitter.com/MusicIndCareers/',
    icon: faTwitter,
  },
  {
    name: 'facebook',
    link: 'https://www.facebook.com/MusicIndCareers/',
    icon: faFacebookF,
  },
  {
    name: 'instagram',
    link: 'https://www.instagram.com/MusicIndCareers/',
    icon: faInstagram,
  },
];

const Footer: React.FC = () => {
  return (
    <Container className="footerWrap" fluid>
      <Container className="footer p-0">
        <Row className="align-items-center">
          <Col xl={1} lg={1} md={12} sm={12}>
            <SVG
              baseURL="/"
              cacheRequests={true}
              className="footerLogo"
              description="MIC Logo"
              loader={<span>Loading...</span>}
              src={Logo}
              title="MIC"
            />
          </Col>
          <Col xl={6} lg={6} md={12} sm={12}>
            <Nav
              className="flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column"
              activeKey="/home"
            >
              <Nav.Item>
                <Nav.Link to={ROUTES.CAREERS} as={Link} eventKey="careers">
                  Careers
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  to={ROUTES.OPPORTUNITIES}
                  as={Link}
                  eventKey="opportunities"
                >
                  Opportunities
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  to={ROUTES.FIND_SOMEONE}
                  as={Link}
                  eventKey="post-a-job"
                >
                  Create Post
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to={ROUTES.ABOUT} as={Link} eventKey="about">
                  About
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to={ROUTES.SUPPORT} as={Link} eventKey="contact">
                  Contact
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to={ROUTES.PRIVACY} as={Link} eventKey="privacy">
                  Privacy Policy
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  to={ROUTES.TERMS}
                  as={Link}
                  eventKey="terms-of-service"
                >
                  Terms Of Service
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col xl={2} lg={2} md={12} sm={12} className="p-0">
            <Nav className="p-0 social-menu flex-row justify-content-lg-start justify-content-md-center justify-content-sm-center justify-content-center">
              {SOCIAL_LINKS.map((socialLink) => (
                <a
                  key={socialLink.name}
                  href={socialLink.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={socialLink.icon} />
                </a>
              ))}
            </Nav>
          </Col>
          <Col
            className="text-xl-right text-lg-right text-md-center text-sm-center text-center copyrightOuter"
            xl={3}
            lg={3}
            md={12}
            sm={12}
          >
            <div className="copyright">
              &copy; The Counter Rhythm Group LLC 2020
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default React.memo(Footer);
